// Require optional shared components
require(['componentLoader'], function(helper) {
  var config = THEHUT.config;

  // Add new locally mapped modules and other dependencies
  helper.promote([
    'carousel',
    'carouselSet',
    'productPageSlider',
    'sliderSet'
  ]);

  helper.requireDependencies();
});

// This module's dependencies only
var deps = [
  'jquery',
  'colourswatches',

  // Undeclared
  'colourswatchesTpl',
  'common'
];

require(deps, function($, colourswatches) {
  colourswatches.colourSwatchesForSelectBoxWithLabel('opts-4');
});
